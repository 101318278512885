<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#68D389"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12>
        <v-checkbox
          small
          dense
          color="#68D389"
          hide-details
          v-model="allChecked"
          class="text-uppercase"
          label="Select all"
          @click="allCheckAdd()"
        ></v-checkbox>
      </v-flex>
    </v-layout>
    <v-layout v-for="item in booking" :key="item._id" hidden-sm-and-down>
      <v-flex md12 pt-4>
        <!-- <router-link :to="'/Booking/paymentDetails?id=' + item.payment.ticket"> -->
        <v-card flat>
          <!-- <div
            :class="
              item.payment.verificationStatus == 'Verified' ? 'line1' : ''
            "
          >
            <span
              v-if="item.payment.verificationStatus == 'Verified'"
              style="
                color: white;
                font-size: 13.5px;
                font-family: poppinssemibold;
                letter-spacing: 2px;
                background-color: red;
                padding-right: 20px;
                padding-left: 20px;
              "
            >
              Verified
            </span>
          </div> -->
          <v-layout wrap pt-3 text-left>
            <v-checkbox
              v-if="allChecked"
              small
              dense
              color="#68D389"
              hide-details
              v-model="item.checkbox"
              @change="checkAdd(item.payment.ticket, item.checkbox)"
            ></v-checkbox>

            <v-checkbox
              v-else
              small
              dense
              color="#68D389"
              hide-details
              v-model="item.checkbox"
              @change="checkAdd(item.payment.ticket, item.checkbox)"
            ></v-checkbox>
            <v-flex xs12 sm12 md6 lg6 xl6>
              <span style="font-size: 16px; font-family: poppinssemibold"
                >Ticket Number:</span
              >
              <span
                style="
                  font-size: 16px;
                  font-family: poppinsbold;
                  color: #68d389;
                "
              >
                #{{ item.ticketNo }}</span
              >
            </v-flex>
            <v-flex xs12 sm6 md6 lg6 text-end>
              <span>
                <a
                  target="_blank"
                  :href="
                    'https://api.wayanadwildlifesanctuary.com/booking/ticketpdf/?id=' +
                    item.payment._id
                  "
                  style="color: #68d389"
                >
                  <v-icon color="#68d389" size="16">
                    mdi-arrow-collapse-down
                  </v-icon>

                  Download Ticket{{ booking.ticketDetail }}
                </a>
              </span>
            </v-flex>
          </v-layout>
          <v-layout
            wrap
            pb-2
            v-for="pgm in item.booking"
            :key="pgm.programme.index"
          >
            <v-flex xs12 md6 pb-1 text-left>
              <span
                style="font-family: poppinsbold; font-size: 18px; color: red"
              >
                {{ pgm.programme.progName }}
              </span>
              <span
                v-if="item.payment.verificationStatus == 'Verified'"
                style="
                  color: white;
                  font-size: 13.5px;
                  font-family: poppinssemibold;
                  letter-spacing: 2px;
                  background-color: red;
                  padding-right: 20px;
                  padding-left: 20px;
                "
              >
                Verified
              </span>
            </v-flex>
            <v-flex v-if=" item.payment.reScheduledProgramAmount" xs12 md3 text-right pb-1>
              <v-layout wrap justify-center>
                <v-flex xs6 sm4 md6 xl9 lg9 pr-1>
                 
                  <span style="font-size: 16px; font-family: poppinssemibold">Rescheduled Amount :</span>
                </v-flex>
                <v-flex xs6 sm8 md4 xl3 lg3>
                  <span
                    style="
                      font-size: 16px;
                      font-family: poppinsbold;
                      color: #68d389;
                    "
                  >
                    INR {{ item.payment.reScheduledProgramAmount }}</span
                  >
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 md3 text-right pb-1>
              <v-layout wrap justify-center>
                <v-flex xs6 sm4 md6 xl9 lg6 pr-1>
                  <span style="font-size: 16px; font-family: poppinssemibold"
                    >Total Amount :</span
                  >
                  
                </v-flex>
                <v-flex xs6 sm8 md4 xl3 lg4>
                  <span
                    style="
                      font-size: 16px;
                      font-family: poppinsbold;
                      color: #68d389;
                    "
                  >
                    INR {{ item.payment.total }}</span
                  >
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex md12>
              <v-card style="min-height: 100px" outlined>
                <v-layout
                  style="
                    font-family: poppinsregular;
                    font-size: 13px;
                    background-color: black;
                    color: white;
                  "
                  wrap
                  pa-2
                  justify-start
                >
                  <v-flex xs2> Name </v-flex>
                  <!-- <v-flex> Email </v-flex> -->
                  <v-flex xs1> Phone </v-flex>
                  <v-flex xs1 text-right> Date of Birth</v-flex>
                  <v-flex xs1 text-center> Gender </v-flex>
                  <v-flex xs1> Type</v-flex>
                
                  <v-flex xs1 text-center> Booking Date</v-flex>
                  <v-flex text-center xs2> ID Proof </v-flex>
                  <v-flex xs2> Timing </v-flex>
                </v-layout>
                <v-layout
                  v-for="guestItem in pgm.details"
                  :key="guestItem.guest._id"
                  style="font-family: poppinsbold; font-size: 13px"
                  wrap
                  pa-2
                  justify-start
                >
                  <v-flex xs2>{{ guestItem.guest.name }} </v-flex>
                  <!-- <v-flex> {{guestItem.guest.email}}</v-flex> -->
                  <v-flex xs1> {{ guestItem.guest.phoneno }}</v-flex>
                  <v-flex xs1 text-right>
                    {{ guestItem.guest.dob.slice(0, 10) }}</v-flex
                  >
                  <v-flex xs1 text-center>
                    {{ guestItem.guest.gender }}
                  </v-flex>
                  <v-flex xs1> {{ guestItem.guest.guestType }} </v-flex>
                 
                  <v-flex xs1 text-center>
                    {{ guestItem.bookingDate.slice(0, 10) }}</v-flex
                  >
                  <v-flex xs2 text-center>
                    <span v-if="guestItem.guest.idproofs">{{
                      guestItem.guest.idproofs.idNumber
                    }}</span>
                    <span v-else>Nil</span>
                  </v-flex>
                  <v-flex xs2>
                    {{ formatTime(guestItem.slotDetail.startTime) }} -
                    {{ formatTime(guestItem.slotDetail.endTime) }}</v-flex
                  >
                </v-layout>
                <v-layout wrap justify-end>
                  <!-- <v-flex xs6 sm4 lg2 align-self-end text-right>
                    <router-link
                      :to="
                        '/ReschedulePage?type=' +
                        pgm.programme._id +
                        '&name=' +
                        pgm.programme.progName +
                        '&caption=' +
                        pgm.programme.caption +
                        '&ticketId=' +
                        item.ticketDetail._id +
                        '&paymentId=' +
                        item.payment._id
                      "
                    >
                      <v-btn plain class="text-capitalize" color="red"
                        >Reschedule
                      </v-btn>
                    </router-link></v-flex
                  > -->
                  <v-flex xs6 sm4 lg2 align-self-end text-right>
                    <v-btn
                      plain
                      class="text-capitalize"
                      @click="
                        getFullOrder(
                          pgm.programme.progName,
                          item.payment.ticket
                        )
                      "
                      color="red"
                      >Cancel(Full Refund)</v-btn
                    ></v-flex
                  >
                  <v-flex xs6 sm4 lg2 align-self-end text-right>
                    <v-btn
                      plain
                      class="text-capitalize"
                      @click="
                        getOrder(pgm.programme.progName, item.payment.ticket)
                      "
                      color="red"
                      >Cancel Booking</v-btn
                    ></v-flex
                  >
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card>
        <!-- </router-link> -->
      </v-flex>
    </v-layout>
    <v-layout v-for="(items, index1) in booking" :key="index1" hidden-md-and-up>
      <v-flex md12 pt-4>
        <router-link :to="'/Admin/paymentDetails?id=' + items.payment.ticket">
          <v-card flat>
            <!-- <div
              :class="
                items.payment.verificationStatus == 'Verified' ? 'line1' : ''
              "
            >
              <span
                v-if="items.payment.verificationStatus == 'Verified'"
                style="
                  color: white;
                  font-size: 13.5px;
                  font-family: poppinssemibold;
                  letter-spacing: 2px;
                  background-color: red;
                  padding-right: 20px;
                  padding-left: 20px;
                "
              >
                Verified
              </span>
            </div> -->
            <v-layout wrap pt-3 text-left>
              <v-checkbox
                v-if="allChecked"
                small
                dense
                color="#68D389"
                hide-details
                v-model="items.checkbox"
                @change="checkAdd(items.payment.ticket, items.checkbox)"
              ></v-checkbox>

              <v-checkbox
                v-else
                small
                dense
                color="#68D389"
                hide-details
                v-model="items.checkbox"
                @change="checkAdd(items.payment.ticket, items.checkbox)"
              ></v-checkbox>
              <v-flex xs12 sm12 md12 lg12 xl12>
                <span style="font-size: 16px; font-family: poppinssemibold"
                  >Ticket Number:</span
                >
                <span
                  style="
                    font-size: 16px;
                    font-family: poppinsbold;
                    color: #68d389;
                  "
                >
                  #{{ items.ticketNo }}</span
                >
              </v-flex>
            </v-layout>
            <v-layout
              wrap
              v-for="pgm1 in items.booking"
              :key="pgm1.programme.index3"
            >
              <v-flex xs12 pb-2 text-left>
                <span
                  style="font-family: poppinsbold; font-size: 18px; color: red"
                >
                  {{ pgm1.programme.progName }}
                </span>
                <span
                  v-if="items.payment.verificationStatus == 'Verified'"
                  style="
                    color: white;
                    font-size: 13.5px;
                    font-family: poppinssemibold;
                    letter-spacing: 2px;
                    background-color: red;
                    padding-right: 20px;
                    padding-left: 20px;
                  "
                >
                  Verified
                </span>
              </v-flex>
              <v-flex xs12>
                <v-card>
                  <v-expansion-panels>
                    <v-expansion-panel
                      v-for="guestItem1 in pgm1.details"
                      :key="guestItem1.guest.index4"
                      style="font-family: poppinsbold; font-size: 13px"
                      wrap
                      pa-2
                      justify-start
                    >
                      <v-expansion-panel-header>
                        <v-layout wrap justify-center>
                          <v-flex xs6>{{ guestItem1.guest.name }} </v-flex>
                          <v-flex xs6>
                            {{ formatTime(guestItem1.slotDetail.startTime) }}
                            -
                            {{
                              formatTime(guestItem1.slotDetail.endTime)
                            }}</v-flex
                          >
                        </v-layout>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-layout
                          style="font-family: poppinsregular; font-size: 13px"
                          wrap
                          pa-2
                          justify-start
                        >
                          <v-flex xs6 sm3>
                            <v-layout wrap justify-center>
                              <v-flex xs12 class="textField2"> Phone </v-flex>
                              <v-flex xs12 class="textField1">
                                {{ guestItem1.guest.phoneno }}
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs6 sm3>
                            <v-layout wrap justify-center>
                              <v-flex xs12 class="textField2">
                                Date of Birth
                              </v-flex>
                              <v-flex xs12 class="textField1">
                                {{ guestItem1.guest.dob.slice(0, 10) }}
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs6 sm3>
                            <v-layout wrap justify-center>
                              <v-flex xs12 class="textField2"> Gender </v-flex>
                              <v-flex xs12 class="textField1">
                                {{ guestItem1.guest.gender }}
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs6 sm3>
                            <v-layout wrap justify-center>
                              <v-flex xs12 class="textField2"> Type </v-flex>
                              <v-flex xs12 class="textField1">
                                {{ guestItem1.guest.guestType }}
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs6 sm3>
                            <v-layout wrap justify-center>
                              <v-flex xs12 class="textField2">
                                ID Proof
                              </v-flex>
                              <v-flex
                                xs12
                                v-if="guestItem1.guest.idproofs"
                                class="textField1"
                              >
                                {{ guestItem1.guest.idproofs.idNumber }}
                              </v-flex>
                              <v-flex xs12 v-else class="textField1">
                                Nil
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card>
              </v-flex>
              <v-flex xs12 md12 py-2>
                <v-layout wrap justify-center>
                  <v-flex xs6 sm6 md6 lg8 text-left>
                    <span style="font-size: 16px; font-family: poppinssemibold"
                      >Total Amount :</span
                    >
                    <br />
                  <span>Rescheduled Amount</span>
                  </v-flex>
                  <v-flex xs6 sm6 md6 lg6>
                    <span
                      style="
                        font-size: 16px;
                        font-family: poppinsbold;
                        color: #68d389;
                      "
                    >
                      INR {{ items.payment.total }}</span
                    >
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
        </router-link>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="#68D389"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center v-if="ticketIds">
      <v-flex
        xs12
        sm12
        md6
        pa-3
        align-self-center
        text-center
        v-if="ticketIds.length > 0"
      >
        <v-card flat width="100%">
          <v-layout wrap justify-center>
            <v-flex xs6 px-2 align-self-center text-center>
              <v-btn
                elevation="5"
                block
                class="text-uppercase"
                @click="checkFullOrder(ticketIds)"
                color="red"
                >Cancel(Full Refund)</v-btn
              ></v-flex
            >
            <v-flex xs6 px-2 align-self-center text-center>
              <v-btn
                elevation="5"
                block
                class="text-uppercase"
                @click="checkOrder(ticketIds)"
                color="red"
                >Cancel Booking</v-btn
              ></v-flex
            >
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>

    <v-dialog
      v-model="cancelOrderDialog"
      :width="
        $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
          ? '100vw'
          : $vuetify.breakpoint.name == 'md'
          ? '50vw'
          : $vuetify.breakpoint.name == 'lg'
          ? '30vw'
          : '20vw'
      "
    >
      <v-card>
        <v-layout pa-4 wrap justify-center>
          <v-flex xs12>
            <span
              style="
                color: #000000;
                font-family: poppinsregular;
                font-size: 16px;
              "
            >
              Do you want to cancel this order?
            </span>
          </v-flex>
          <v-flex xs12>
            <v-layout wrap justify-start>
              <v-flex xs12 py-1>
                <span
                  class="itemCaption"
                  style="font-family: poppinsmedium; font-size: 18px"
                >
                  {{ progName }}
                </span>
              </v-flex>
              <v-flex xs12>
                <!-- <v-checkbox
                  v-model="cancelAll"
                  dense
                  hide-details
                  :ripple="false"
                  color="#68D389"
                >
                  <template v-slot:label>
                    Also cancel all orders in this booking
                  </template>
                </v-checkbox> -->
                <span style="font-family: poppinsmedium; font-size: 14px">
                  It will cancel all orders in this booking</span
                >
              </v-flex>
              <v-flex xs12 v-if="cancelAll">
                <v-layout wrap justify-center>
                  <v-flex
                    xs12
                    v-for="(item, i) in orderItems.programme"
                    :key="i"
                    py-2
                  >
                    <span
                      style="font-family: poppinsbold; font-size: 16px"
                      v-if="progName != item.progName"
                    >
                      <b>{{ item.progName }}</b>
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12>
                <v-divider></v-divider>
              </v-flex>
              <v-flex xs12>
                <v-layout wrap justify-center>
                  <v-flex xs8 py-1>
                    <span
                      style="
                        font-family: poppinsregular;
                        font-size: 16px;
                        color: #aaa;
                      "
                    >
                      Refundable Amount
                    </span>
                  </v-flex>

                  <v-flex xs4 py-1 text-right>
                    <span
                      style="font-family: poppinssemibold; font-size: 16px"
                      v-html="
                        cancelAll
                          ? `${orderItems.totalAmountToRefund} INR`
                          : `${orderItems.totalAmountToRefund} INR`
                      "
                    >
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs6 px-2 py-4>
            <v-btn
              small
              outlined
              tile
              block
              depressed
              dark
              color="#C62525"
              @click="cancelOrder"
              class="itemValue"
            >
              Yes
            </v-btn>
          </v-flex>
          <v-flex xs6 px-2 py-4>
            <v-btn
              small
              tile
              block
              depressed
              dark
              color="#1FB436"
              @click="cancelOrderDialog = false"
              class="itemValue"
            >
              No
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <!-- Full Refund -->
    <v-dialog
      v-model="cancelFullDialog"
      :width="
        $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
          ? '100vw'
          : $vuetify.breakpoint.name == 'md'
          ? '50vw'
          : $vuetify.breakpoint.name == 'lg'
          ? '30vw'
          : '20vw'
      "
    >
      <v-card>
        <v-layout pa-4 wrap justify-center>
          <v-flex xs12>
            <span
              style="
                color: #000000;
                font-family: poppinsregular;
                font-size: 16px;
              "
            >
              Do you want to cancel this order and refund full amount?
            </span>
          </v-flex>
          <v-flex xs12>
            <v-layout wrap justify-start>
              <v-flex xs12 py-1>
                <span
                  class="itemCaption"
                  style="font-family: poppinsmedium; font-size: 18px"
                >
                  {{ progName }}
                </span>
              </v-flex>
              <v-flex xs12>
                <!-- <v-checkbox
                  v-model="cancelAll"
                  dense
                  hide-details
                  :ripple="false"
                  color="#68D389"
                >
                  <template v-slot:label>
                    Also cancel all orders in this booking
                  </template>
                </v-checkbox> -->
                <span style="font-family: poppinsmedium; font-size: 14px">
                  It will cancel all orders in this booking</span
                >
              </v-flex>
              <v-flex xs12 v-if="cancelAll">
                <v-layout wrap justify-center>
                  <v-flex
                    xs12
                    v-for="(item, i) in orderItems.programme"
                    :key="i"
                    py-2
                  >
                    <span
                      style="font-family: poppinsbold; font-size: 16px"
                      v-if="progName != item.progName"
                    >
                      <b>{{ item.progName }}</b>
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12>
                <v-divider></v-divider>
              </v-flex>
              <v-flex xs12>
                <v-layout wrap justify-center>
                  <v-flex xs8 py-1>
                    <span
                      style="
                        font-family: poppinsregular;
                        font-size: 16px;
                        color: #aaa;
                      "
                    >
                      Refundable Amount
                    </span>
                  </v-flex>

                  <v-flex xs4 py-1 text-right>
                    <span
                      style="font-family: poppinssemibold; font-size: 16px"
                      v-html="
                        cancelAll
                          ? `${orderItems.totalAmountToRefund} INR`
                          : `${orderItems.totalAmountToRefund} INR`
                      "
                    >
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs6 px-2 py-4>
            <v-btn
              small
              outlined
              tile
              block
              depressed
              dark
              color="#C62525"
              @click="cancelFullRefund"
              class="itemValue"
            >
              Yes
            </v-btn>
          </v-flex>
          <v-flex xs6 px-2 py-4>
            <v-btn
              small
              tile
              block
              depressed
              dark
              color="#1FB436"
              @click="cancelFullDialog = false"
              class="itemValue"
            >
              No
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>

    <!-- Selected Cancellation -->
    <v-dialog
      v-model="selectedDialog"
      :width="
        $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
          ? '100vw'
          : $vuetify.breakpoint.name == 'md'
          ? '50vw'
          : $vuetify.breakpoint.name == 'lg'
          ? '30vw'
          : '20vw'
      "
    >
      <v-card>
        <v-layout pa-4 wrap justify-center>
          <v-flex xs12>
            <span
              v-if="!cancelAll"
              style="
                color: #000000;
                font-family: poppinsregular;
                font-size: 16px;
              "
            >
              Do you want to cancel selected orders?
            </span>
            <span
              v-else
              style="
                color: #000000;
                font-family: poppinsregular;
                font-size: 16px;
              "
            >
              Do you want to cancel selected orders and refund full amount?
            </span>
          </v-flex>
          <v-flex xs12>
            <v-layout wrap justify-start>
              <v-flex xs12 py-1 v-for="(item, i) in programData" :key="i">
                <v-flex xs12 py-1 v-for="(item2, j) in item.programme" :key="j">
                  <span
                    class="itemCaption"
                    style="font-family: poppinsmedium; font-size: 18px"
                  >
                    {{ item2.progName }}
                  </span>
                </v-flex>
              </v-flex>
              <v-flex xs12>
                <!-- <v-checkbox
                  v-model="cancelAll"
                  dense
                  hide-details
                  :ripple="false"
                  color="#68D389"
                >
                  <template v-slot:label>
                    Also cancel all orders in this booking
                  </template>
                </v-checkbox> -->
                <span style="font-family: poppinsmedium; font-size: 14px">
                  It will cancel all orders in this booking</span
                >
              </v-flex>
              <!-- <v-flex xs12 v-if="cancelAll">
                <v-layout wrap justify-center>
                  <v-flex
                    xs12
                    v-for="(item, i) in orderItems.programme"
                    :key="i"
                    py-2
                  >
                    <span
                      style="font-family: poppinsbold; font-size: 16px"
                      v-if="progName != item.progName"
                    >
                      <b>{{ item.progName }}</b>
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex> -->
              <v-flex xs12>
                <v-divider></v-divider>
              </v-flex>
              <v-flex xs12>
                <v-layout wrap justify-center>
                  <v-flex xs8 py-1>
                    <span
                      style="
                        font-family: poppinsregular;
                        font-size: 16px;
                        color: #aaa;
                      "
                    >
                      Refundable Amount
                    </span>
                  </v-flex>

                  <v-flex xs4 py-1 text-right>
                    <span
                      style="font-family: poppinssemibold; font-size: 16px"
                      v-html="
                        cancelAll
                          ? `${totalSelectectedAmt} INR`
                          : `${totalSelectectedAmt} INR`
                      "
                    >
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs6 px-2 py-4 v-if="!cancelAll">
            <v-btn
              small
              outlined
              tile
              block
              depressed
              dark
              color="#C62525"
              @click="selRefund()"
              class="itemValue"
            >
              Yes
            </v-btn>
          </v-flex>
          <v-flex xs6 px-2 py-4 v-else>
            <v-btn
              small
              outlined
              tile
              block
              depressed
              dark
              color="#C62525"
              @click="selFullRefund()"
              class="itemValue"
            >
              Yes
            </v-btn>
          </v-flex>
          <v-flex xs6 px-2 py-4>
            <v-btn
              small
              tile
              block
              depressed
              dark
              color="#1FB436"
              @click="selectedDialog = false"
              class="itemValue"
            >
              No
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// import ImageLoader from "@/components/Common/imageLoader";
import axios from "axios";
export default {
  props: ["booking", "pages"],
  data() {
    return {
      currentPage: 1,
      cancelOrderDialog: false,
      cancelFullDialog: false,
      orderItems: [],
      programData: {},
      cancelAll: false,
      progName: "",
      bookingsId: "",
      ticketsId: "",
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      isFullRefund: false,
      checkbox: false,
      allChecked: false,
      ticketIds: [],
      selectedDialog: false,
      totalSelectectedAmt: 0,
    };
  },
  components: {
    // ImageLoader,
  },
  watch: {
    currentPage() {
      this.winStepper();
    },
  },
  mounted() {
    // console.log("hk", this.booking);
  },
  methods: {
    winStepper() {
      // console.log("step", this.currentPage);

      this.allChecked = false;
      this.allCheckAdd();
      this.$emit("stepper", {
        getData: true,
        currentPage: this.currentPage,
        pages: this.pages,
      });
    },
    allCheckAdd() {
      if (this.allChecked) {
        this.allChecked = true;
        this.ticketIds = [];
        for (let i = 0; i < this.booking.length; i++) {
          this.booking[i].checkbox = true;
          if (this.booking[i].payment) {
            this.checkAdd(this.booking[i].payment.ticket, true);
            // console.log("ok")
          }
        }
        // console.log("if");
        // console.log("if-tick", this.ticketIds);
      } else {
        for (let i = 0; i < this.booking.length; i++) {
          this.booking[i].checkbox = false;
          this.checkAdd(this.booking[i].payment.ticket, false);
        }
        // console.log("else");
        // console.log("else-tick", this.ticketIds);
      }
    },
    checkAdd(item, check) {
      // console.log("Status: ", check);

      if (check == true) {
        this.ticketIds.push(item);
      } else {
        if (this.ticketIds.length <= 12) {
          this.allChecked = false;
        }
        for (let i = 0; i < this.ticketIds.length; i++) {
          if (item == this.ticketIds[i]) {
            this.ticketIds.splice(i, 1);
            // console.log(item);
          }
        }
      }

      // console.log("slicedJTick: ", this.ticketIds);
      // console.log(item);
    },
    getOrder(prgName, tickid) {
      // this.bookingsId=bookid
      this.ticketsId = tickid;
      // console.log("kkk", bookid);
      this.progName = prgName;
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/cancel/getticketrefunddata",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          ticketId: tickid,
          isFullRefund: false,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.cancelOrderDialog = true;
            this.programData = response.data.data.program;
            this.orderItems = response.data.data.ticket;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    cancelOrder() {
      // var bookingId = this.bookingsId;
      // if (this.cancelAll) bookingId = null;
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/cancel/adminrequest",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          ticketId: this.ticketsId,
          isFullRefund: false,
          // bookingId: bookingId,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.cancelOrderDialog = false;
            this.$emit("stepper", {
              getData: true,
              currentPage: this.currentPage,
              pages: this.pages,
            });
            this.msg = response.data.msg;
            this.showSnackBar = true;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getFullOrder(prgName, tickid) {
      // this.bookingsId=bookid
      this.ticketsId = tickid;
      // console.log("kkk", bookid);
      this.progName = prgName;
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/cancel/getticketrefunddata",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          ticketId: tickid,
          isFullRefund: true,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.cancelFullDialog = true;
            this.programData = response.data.data.program;
            this.orderItems = response.data.data.ticket;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    cancelFullRefund() {
      // var bookingId = this.bookingsId;
      // if (this.cancelAll) bookingId = null;
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/cancel/adminrequest",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          ticketId: this.ticketsId,
          isFullRefund: true,
          // bookingId: bookingId,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.cancelFullDialog = false;
            this.$emit("stepper", {
              getData: true,
              currentPage: this.currentPage,
              pages: this.pages,
            });
            this.msg = response.data.msg;
            this.showSnackBar = true;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    checkFullOrder(ids) {
      this.appLoading = true;
      this.cancelAll = true;
      axios({
        method: "POST",
        url: "/cancel/getmultipleticketrefunddata",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          ticketIds: ids,
          isFullRefund: true,
          // bookingId: bookingId,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.selectedDialog = true;
            this.programData = response.data.data;
            // this.orderItems = response.data.data.ticket;
            this.totalSelectectedAmt = response.data.totalAmountToRefund;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    checkOrder(ticketIds) {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/cancel/getmultipleticketrefunddata",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          ticketIds: ticketIds,
          isFullRefund: false,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.selectedDialog = true;
            this.programData = response.data.data;
            // this.orderItems = response.data.data.ticket;
            this.totalSelectectedAmt = response.data.totalAmountToRefund;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },

    selFullRefund() {
      // var bookingId = this.bookingsId;
      // if (this.cancelAll) bookingId = null;
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/cancel/adminmultiplerequest",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          ticketIds: this.ticketIds,
          isFullRefund: true,
          // bookingId: bookingId,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.ticketIds = [];
            this.selectedDialog = false;
            this.$emit("stepper", {
              getData: true,
              currentPage: this.currentPage,
              pages: this.pages,
            });
            this.msg = response.data.msg;
            this.showSnackBar = true;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },

    selRefund() {
      // var bookingId = this.bookingsId;
      // if (this.cancelAll) bookingId = null;
      console.log(this.ticketIds);
      if (this.ticketIds) {
        this.appLoading = true;
        axios({
          method: "POST",
          url: "/cancel/adminmultiplerequest",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            ticketIds: this.ticketIds,
            isFullRefund: false,
          },
        })
          .then((response) => {
            this.appLoading = false;
            if (response.data.status) {
              this.selectedDialog = false;
              this.ticketIds = [];
              this.$emit("stepper", {
                getData: true,
                currentPage: this.currentPage,
                pages: this.pages,
              });
              this.msg = response.data.msg;
              this.showSnackBar = true;
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            console.log(err);
          });
      }
    },
    formatDate(item) {
      var dt = new Date(item);
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
    formatTime(item) {
      if (!item) return;
      var hours = Number(item.split(":")[0]);
      var minutes = Number(item.split(":")[1]);
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
  },
};
</script>

<style scoped>
.line1 {
  width: 481px;
  height: 155px;
  z-index: 2;
  border-bottom: 0px solid white;
  -webkit-transform: translateY(20px) translateX(5px) rotate(-26deg);
  position: absolute;
  top: -33px;
  left: -13px;
}
</style>
