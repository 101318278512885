<template>
  <div>
    <v-layout v-for="(item, i) in reservationData" :key="i" wrap>
      <v-flex xs12 md12 pt-4>
        <v-card outlined>
          <v-layout wrap justify-center>
            <v-flex xs12>
              <v-layout wrap pa-2>
                <v-flex xs4 lg2>
                  <v-img
                    v-if="item.slotDetail.slot.programme.photos.length > 0"
                    width="175px"
                    height="113px"
                    :src="mediaURL + item.slotDetail.slot.programme.photos[0]"
                  >
                    <template v-slot:placeholder>
                      <ImageLoader />
                    </template>
                  </v-img>
                  <v-img
                    v-else
                    width="175px"
                    height="113px"
                    src="../../../assets/images/home.jpg"
                  >
                    <template v-slot:placeholder>
                      <ImageLoader />
                    </template>
                  </v-img>
                </v-flex>
                <v-flex xs8 lg10 pa-2>
                  <v-layout wrap justify-start>
                    <v-flex xs12 text-left>
                      <span class="itemCaption" style="font-size: 18px">
                        {{ item.slotDetail.slot.programme.progName }}
                      </span>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex md3 text-left>
                      <span class="textField2"> Selected Date</span><br />
                      <span class="cardSubHeading" style="letter-spacing: 0px"
                        >{{ formatDate(item.bookingDate) }}
                      </span>
                    </v-flex>
                 
                    <v-flex md3 text-left>
                      <span class="textField2"> Selected Slot</span><br />
                      <span class="cardSubHeading" style="letter-spacing: 0px">
                        {{ formatTime(item.slotDetail.startTime) }} -
                        {{ formatTime(item.slotDetail.endTime) }}
                      </span>
                    </v-flex>

                    <v-flex md3 text-left >
                      <span v-if="item.slotDetail.slot.programme.isCottage" class="textField2">
                        Rooms Reserved</span
                      >
                      <span v-else class="textField2"> Seats Reserved</span
                      ><br />
                      <span class="cardSubHeading" style="letter-spacing: 0px">
                        {{ item.reserved }}
                      </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>

              <v-divider></v-divider>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import ImageLoader from "@/components/Common/imageLoader";

export default {
  props: ["reservationData"],
  components: {
    ImageLoader,
  },
   methods:{
       formatDate(item) {
      var dt = new Date(item);
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
    formatTime(item) {
      if (!item) return;
      var hours = Number(item.split(":")[0]);
      var minutes = Number(item.split(":")[1]);
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
  }
};
</script>